<template>
  <div
    class="result-card"
    :class="{
      'result-card_highlight': highlighted,
    }"
    @click="$emit('click', $event)"
  >
    <router-link
      v-if="to"
      class="result-card__link"
      :to="to"
    ></router-link>
    <div class="result-card__container">
      <div class="result-card__member">
        <Member
          :name="name"
          :img="avatar"
          :imgSize="48"
          @clickImg.prevent.stop="$emit('clickMemberImg', $event)"
        >
          <div class="result-card__info text-xs">
            <slot name="info"></slot>
          </div>
          <template v-slot:action>
            <slot name="action"></slot>
          </template>
        </Member>
      </div>
      <div class="result-card__caret">
        <Icon size="l" name="right"></Icon>
      </div>
      <div class="result-card__cols">
        <slot name="cols"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Member from '@/components/Member/Member.vue';

export default {
  name: 'ResultCard',
  components: {
    Member,
  },
  props: {
    name: String,
    avatar: String,
    to: [String, Object],
    highlighted: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.result-card {
  display: block;
  color: inherit;
  text-decoration: none;
  outline: none;
  background-color: $color-white;
  border: 2px solid $color-black-15;
  border-radius: $border-radius-secondary;
  padding: 2px;
  position: relative;

  & + & {
    margin-top: 12px;
  }

  &_highlight {
    border-color: $color-primary;
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 8px;

    @media (min-width: breakpoint(md)) {
      padding: 12px;
    }

    @media (min-width: breakpoint(lg)) {
      padding: 16px;
      flex-wrap: nowrap;
    }
  }

  &__member {
    flex: 1 1 0;
    min-width: 0;
    margin-right: 16px;

    @media (min-width: breakpoint(lg)) {
      flex: 0 0 auto;
      width: 390px;
      max-width: 35%;
      margin-right: 40px;
    }
  }

  &__cols {
    display: flex;
    flex: 1 1 100%;
    justify-content: space-around;

    @media (max-width: breakpoint(lg, max)) {
      border-top: 1px solid $color-black-15;
      width: 100%;
      padding-top: 0.75rem;
      margin: 1rem -6px 0;
    }
  }

  &__col {
    width: 120px;
    flex: 0 0 auto;

    @media (max-width: breakpoint(lg, max)) {
      flex: 1 1 0;
      min-width: 0;
      text-align: center;
      padding: 0 6px;
    }
  }

  &__col-value {

    @media (min-width: breakpoint(md)) {
      @include text-lg;
    }
  }

  &__caret {
    flex: 0 0 auto;
    color: $color-black-45;

    @media (min-width: breakpoint(lg)) {
      order: 1;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-left: -20px;
  }

  ::v-deep &__info-item {
    margin-top: 4px;
    margin-left: 20px;
  }
}
</style>
