<template>
  <div
    class="member"
    :class="{ 'member_stacked': stacked, 'member_muted': muted }"
  >
    <div class="member__container">
      <div class="member__main">
        <div
          :is="imgTo ? 'router-link' : imgHref ? 'a' : 'div'"
          class="member__img"
          :class="[imgOffset && 'member__img_' + imgOffset, !imgSizeFixed && 'member__img_fluid']"
          :href="imgHref"
          :to="imgTo"
          @click="$emit('clickImg', $event)"
        >
          <Avatar
            class="member__avatar"
            :initials="initials"
            :username="name"
            :img="img"
            :size="imgSize"
          ></Avatar>
          <span v-if="$slots.action" class="member__action">
            <slot name="action"></slot>
          </span>
          <slot name="avatar"></slot>
        </div>
        <div class="member__info">
          <div>
            <div
              :is="nameTo ? 'router-link' : nameHref ? 'a' : 'div'"
              class="member__name"
              :href="nameHref"
              :to="nameTo"
            >
              <slot
                name="name"
                :shortName="shortName"
                :fullName="name"
              >
                {{ name }}
              </slot>
            </div>
          </div>
          <slot></slot>
        </div>
      </div>
      <div class="member__actions" v-if="$slots.actions">
        <slot name="actions"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/Avatar/Avatar.vue';

export default {
  name: 'Member',
  components: {
    Avatar,
  },
  props: {
    name: String,
    initials: String,
    img: String,
    imgSize: Number,
    info: String,
    imgTo: [String, Object],
    imgHref: String,
    nameTo: [String, Object],
    nameHref: String,
    imgSizeFixed: {
      type: Boolean,
      default: false,
    },
    stacked: Boolean,
    muted: Boolean,
  },
  computed: {
    imgOffset() {
      return this.imgSize > 48 ? 'lg' : null;
    },
    shortName() {
      if (!this.name) {
        return '';
      }
      const arr = this.name.split(' ');
      return `${arr[1]} ${arr[0].slice(0, 1)}.`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./Member";
</style>
