<template>
  <!-- Medal -->
  <v-popover
    v-if="medalPosition"
    placement="bottom-center" trigger="hover click"
  >
    <img
      :src="medalPosition > 3
        ? require('@/assets/img/other-place-medal.svg')
        : require(`@/assets/img/${medalPosition}-place-medal-empty.svg`)"
      :alt="medalPosition"
      style="width: 24px; height: 24px;"
    >
    <slot></slot>
    <template slot="popover">
      <div v-html="tooltipText"></div>
    </template>
  </v-popover>
  <!-- Position -->
  <div v-else>
    <div v-html="positionText || '--'"></div>
    <slot></slot>
  </div>
</template>

<script>
import { ResultStatus } from '@/enums';

export default {
  name: 'ResultPosition',
  props: {
    position: Number,
    status: Number,
    leadersCount: {
      type: Number,
      default: 0,
    },
    isRelay: Boolean,
  },
  data() {
    return {
      ReversedResultStatus: this.$reversedEnum(ResultStatus),
    };
  },
  computed: {
    tooltipText() {
      return this.isRelay
        ? `${this.$t('result.team_took_place', [this.medalPosition])}. ${this.$t('result.result_excluded_abs')}`
        : `${this.$t('result.participant_took_place', [this.medalPosition])}. ${this.$t('result.result_excluded_abs')}`;
    },
    medalPosition() {
      const medalPosition = this.leadersCount + this.position + 1;

      return medalPosition > 0 && medalPosition <= this.leadersCount
        ? medalPosition
        : null;
    },
    positionText() {
      const positionText = this.ReversedResultStatus[this.status] === 'Finish'
        ? this.position
        : this.ReversedResultStatus[this.status] || this.position;

      if (positionText < 1) {
        return null;
      }

      return positionText;
    },
  },
};
</script>
